<!--  -->
<template>
    <div class="brand">
      <!-- 文档头 -->
      <div class="t-header">
        <div class="t-header-box">
          <div class="brand-name">
            <h5 class="title">多点品牌分析—惠氏</h5>
            <!-- <h5>品牌分析</h5> -->
            <h5>报告模板</h5>
          </div>
          <div class="data-talks"></div>
          <div class="people"></div>
          <div class="yellow-line"></div>
          <div class="blue-line"></div>
          <div></div>
        </div>
      </div>
  
      <div class="content">
        <ul class="clearfix">
          <li class="e-c-list">
            <a href="/models/brand/html/huishi/1-整体概况-图列表.html" target="_blank">
              <div class="e-c-list-item">
                <div class="list-item-name">01-整体概况</div>
                <div class="list-item-details">--到店+到家GMV小球对比图</div>
                <div class="list-item-details">--GMV折线&柱状图</div>
              </div>
            </a>
          </li>
          <li class="e-c-list">
            <a href="/models/brand/html/huishi/2-用户画像-图列表.html" target="_blank">
              <div class="e-c-list-item">
                <div class="list-item-name">02-用户画像</div>
                <div class="list-item-details">--用户性别饼图*3</div>
                <div class="list-item-details">
                  --年龄组成横向堆叠图，90天下单次数横向堆叠图
                </div>
              </div>
            </a>
          </li>
          <li class="e-c-list">
            <a href="/models/brand/html/huishi/3-购物篮-图列表.html" target="_blank">
              <div class="e-c-list-item">
                <div class="list-item-name">03-购物篮</div>
                <div class="list-item-details">--购物篮指标对比柱状图</div>
                <div class="list-item-details">--品牌购物篮指标趋势图</div>
              </div>
            </a>
          </li>
          <li class="e-c-list">
            <a href="/models/brand/html/huishi/4-渠道概况-图列表.html" target="_blank">
              <div class="e-c-list-item">
                <div class="list-item-name">04-渠道概况</div>
                <div class="list-item-details">--渠道销售省市分布</div>
                <div class="list-item-details">--各大渠道销售的柱状图</div>
              </div>
            </a>
          </li>
          <li class="e-c-list">
            <a href="/models/brand/html/huishi/5-用户品牌流转-图列表.html" target="_blank">
              <div class="e-c-list-item">
                <div class="list-item-name">05-用户品牌流转</div>
                <div class="list-item-details">--用户品牌流转图（多对多）</div>
                <div class="list-item-details">
                  --用户品牌流转图（限定中间流转品牌）
                </div>
              </div>
            </a>
          </li>
          <li class="e-c-list">
            <a href="/models/brand/html/huishi/6-新老客分析.html" target="_blank">
              <div class="e-c-list-item">
                <div class="list-item-name">06-新老客分析</div>
                <div class="list-item-details">--新老客的折线+柱状组合图</div>
                <div class="list-item-details">--潜在用户饼图</div>
              </div>
            </a>
          </li>
          <li class="e-c-list">
            <a href="/models/brand/html/huishi/7-库存分析-图列表.html" target="_blank">
              <div class="e-c-list-item">
                <div class="list-item-name">07-库存分析</div>
                <div class="list-item-details">
                  --SKU在架柱状图、 库存周转天数柱状图
                </div>
                <div class="list-item-details">
                  --有货率柱状图、 60天无动销柱状图
                </div>
              </div>
            </a>
          </li>
          <li class="e-c-list">
            <a href="/models/brand/html/huishi/8-购物链路.html" target="_blank">
              <div class="e-c-list-item">
                <div class="list-item-name">08-购物链路</div>
                <div class="list-item-details">--用户加购饼图、 用户搜索词云</div>
                <div class="list-item-details">
                  --商详页、凑单页、搜索结果页横向柱状图
                </div>
              </div>
            </a>
          </li>
          <li class="e-c-list">
            <a href="/models/brand/html/huishi/9-品类分析.html" target="_blank">
              <div class="e-c-list-item">
                <div class="list-item-name">09-品类分析</div>
                <div class="list-item-details">--品类堆叠图</div>
                <div class="list-item-details">--多点品类饼图、 品牌品类饼图</div>
              </div>
            </a>
          </li>
  
  
          <li class="e-c-list">
            <a href="/models/brand/html/huishi/10-用户渗透与复购-图列表-1.html" target="_blank">
              <div class="e-c-list-item">
                <div class="list-item-name">10-用户渗透与复购-图列表-1</div>
                <div class="list-item-details">--渗透率&复购次数(月份)</div>
                <div class="list-item-details">--渗透率&复购次数(商品)</div>
              </div>
            </a>
          </li>
  
  
          <li></li>
          <li></li>
        </ul>
      </div>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {};
    },
    //生命周期 - 创建完成（访问当前this实例）
    created() {},
    //生命周期 - 挂载完成（访问DOM元素）,函数调用
    mounted() {},
    //计算属性
    computed: {},
    //函数
    methods: {},
    //监控
    watch: {},
  };
  </script>
  <style scoped>
  .t-header {
    height: 230px;
    background: url(../../assets/images/t-header-bg.jpg) no-repeat center;
  }
  .t-header-box {
    width: 80%;
    height: 100%;
    position: relative;
    margin: 0 auto;
  }
  .brand-name {
    position: absolute;
    top: 4%;
    left: 3%;
    color: #5793f3;
    font-size: 60px;
    padding-top: 40px;
  }
  .brand-name h5 {
    margin: 0 10px;
    font-size:40px;
  }
  .data-talks {
    width: 300px;
    height: 200px;
    background: url(../../assets/images/t-header-logo2.png) no-repeat center;
    background-size: cover;
    position: absolute;
    top: 4%;
    right: -3%;
    z-index: 2;
  }
  .people {
    width: 54%;
    height:100%;
    background: url(../../assets/images/t-header-logo.png) no-repeat center;
    background-size: contain;
    position: absolute;
    top: 14%;
    right: -10%;
    z-index: 1;
  }
  .yellow-line {
    width: 40%;
    height: 4px;
    background-color: #ffd700;
    position: absolute;
    bottom: 4%;
    left: 0;
  }
  .blue-line {
    width: 100%;
    height: 4px;
    background-color: #5793f3;
    position: absolute;
    bottom: 0%;
    left: 0;
  }
  
  /* contetn */
  .content {
    padding: 30px 0;
  }
  .content a {
    text-decoration: none;
    color: #5793f3;
  }
  .content .e-c-list {
    width: 33.33%;
    min-height: 90px;
    background-color: #fff;
    color: #5793f3;
    font-size: 12px;
    font-weight: bold;
    float: left;
    margin-bottom: 20px;
  }
  .content .e-c-list-item {
    height: 98%;
    margin: 0 10px;
    border: 1px solid #5793f3;
  }
  .e-c-list-item .list-item-name {
    background-color: #5793f3;
    height: 30px;
    line-height: 30px;
    color: #fff;
    font-size: 16px;
    text-align: left;
    padding-left: 10%;
  }
  .e-c-list-item .list-item-details {
    text-align: left;
    padding-left: 5%;
    margin: 10px 0;
  }
  .brand {
    overflow-y: auto;
    padding-bottom: 50px;
  }
  </style>
  